<template>
  <div class="host">
    <div class="controlArea">
      <div v-show="gotOrder" class="overallAmount">
        RM <span>{{ productsTotalToString(products) }}</span>
        <span v-show="extraShippingFee" class="extraShippingFee"
          >满 {{ this.minFreeShippingAmount }} 免邮</span
        >
      </div>
      <img v-show="!gotOrder" class="logo" src="./assets/logo.png" />
      <div v-show="gotOrder" class="buttonArea">
        <button @click="reset()" type="button">重来</button>
        <a :href="whatsAppLink" class="button light" target="_blank">下单</a>
      </div>
    </div>
    <div class="products">
      <div
        v-for="product in products"
        v-bind:key="product.name"
        v-bind:class="{ active: product.soldOut || product.isOpen }"
        class="product"
      >
        <div class="frameImage">
          <img :src="convertFlickrImage(product.imageSrc)" />
          <!-- <img
            :src="
              'https://drive.google.com/uc?export=view&id=' + product.imageSrc
            "
          /> -->
          <div class="price">
            <span v-if="product.showName">{{ product.name }}</span> <br />RM
            {{ decimalPoint(product.price) }}
            <img
              v-if="product.label === 'top'"
              src="./assets/topIcon.png"
              class="imageLabel"
            />
            <img
              v-if="product.label === 'best'"
              src="./assets/bestIcon.png"
              class="imageLabel"
            />
          </div>
          <div v-show="!product.soldOut" @click="open(product)" class="cover">
            <div class="box">
              <p class="description">
                <b>{{ product.name + "\n" }}</b
                >{{ product.description }}
              </p>
              <div :class="{ open: product.order > 0 }" class="selected">
                <p>
                  已选择 <b>{{ product.order }}</b> {{ product.unit }}
                </p>
                <p class="total">
                  总共 RM <span>{{ productTotal(product) }}</span>
                </p>
              </div>
              <div class="orderButtonArea">
                <button @click="close(product)" class="small" type="button">
                  取消
                </button>
                <button
                  @click="product.order++"
                  class="primary small"
                  type="button"
                >
                  <span v-show="product.order > 0">再</span>来 1
                  {{ product.unit }}
                </button>
              </div>
            </div>
          </div>
          <div v-show="product.soldOut" class="cover center">
            <div class="box">已售完</div>
          </div>
        </div>
        <div v-show="!product.soldOut" class="learnMoreButtonArea">
          <button
            v-show="product.isOpen"
            @click="product.isOpen = false"
            class="medium"
            type="button"
          >
            关闭
          </button>
          <button
            v-show="!product.isOpen"
            @click="product.isOpen = true"
            class="medium"
            type="button"
          >
            了解更多
          </button>
          <button
            @click="
              product.order++;
              product.isOpen = true;
            "
            class="primary medium"
            type="button"
          >
            <span v-show="product.order > 0">再</span>来 1
            {{ product.unit }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isFixedShippingAmount" class="note">
      消费满 RM {{ this.minFreeShippingAmount }} 或以上即可免邮哦!
    </div>
    <div class="cta">
      <a :href="whatsAppLink" class="button light" target="_blank"
        >立即<span>{{
          gotOrder ? "下单 (总共 RM " + overallTotal(products) + ")" : "联系"
        }}</span></a
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "App",
  setup() {
    const params = new URLSearchParams(window.location.search);
    let url = "Datas"

    if (params.get("notFreeShipping") === "1") {
      url = "Datas2";
    }

    const fetchDatas = fetch(
      // `https://docs.google.com/spreadsheets/d/1GNoQNj_nKo0qVoqQKnae-tvM6i-NUWyPqzS35_r4K7s/gviz/tq?tqx=out:json&sheet=Datas`
      `https://docs.google.com/spreadsheets/d/1GNoQNj_nKo0qVoqQKnae-tvM6i-NUWyPqzS35_r4K7s/gviz/tq?tqx=out:json&sheet=${url}`
    );
    let products = ref([]);

    Promise.all([fetchDatas]).then((res) => {
      res[0].text().then((rep) => {
        const allDatas = JSON.parse(rep.substring(47).slice(0, -2));
        products.value = allDatas.table.rows.map((data) => {
          return {
            name: data.c[0].v,
            showName: data.c[1].v,
            price: data.c[2].v,
            imageSrc: data.c[7].v,
            order: 0,
            unit: data.c[3].v,
            description: data.c[4].v,
            isOpen: false,
            soldOut: data.c[6].v,
            label: data.c[5] === null ? "" : data.c[5].v,
          };
        });
      });
    });

    return {
      products,
    };
  },
  data() {
    return {
      extraShippingFee: true,
      gotOrder: false,
      whatsAppLink:
        "https://api.whatsapp.com/send/?phone=60179582748&text=您好!",
      minFreeShippingAmount: 60,
      shippingAmount: 8,
      shippingLocation: "西马",
      isFixedShippingAmount: false,
      fixedShippingAmount: 8,
    };
  },
  methods: {
    open(product) {
      if (product.isOpen === false) {
        product.isOpen = true;
      }
    },
    close(product) {
      if (product.isOpen === true) {
        setTimeout(() => {
          product.isOpen = false;
          product.order = 0;
        });
      }
    },
    decimalPoint(val) {
      return val.toFixed(2);
    },
    productTotal(product) {
      return (product.price * product.order).toFixed(2);
    },
    productsTotal(products) {
      return products.reduce(
        (total, product) => total + product.price * product.order,
        0
      );
    },
    productsTotalToString(products) {
      return this.productsTotal(products).toFixed(2);
    },
    overallTotal(products) {
      let productTotalAmount = this.productsTotal(products);

      if (this.extraShippingFee) {
        productTotalAmount += this.shippingAmount;
      }
      if (this.isFixedShippingAmount) {
        productTotalAmount += this.fixedShippingAmount;
      }
      return productTotalAmount.toFixed(2);
    },
    reset() {
      this.products.forEach((product) => (product.order = 0));
    },
    convertFlickrImage(imageLink) {
      // console.log(imageLink)
      return imageLink.replace(/_[a-z].jpg/, `_w.jpg`);
    },
  },
  watch: {
    products: {
      deep: true,
      handler(products) {
        const filteredProducts = products.filter((p) => p.order > 0);
        let orderList = [];
        filteredProducts.forEach((product) => {
          orderList.push(
            `${product.name} x${product.order}${product.unit} =${(
              product.price * product.order
            ).toFixed(2)}`
          );
        });

        this.gotOrder = orderList.length > 0;
        this.extraShippingFee =
          this.productsTotal(products) < this.minFreeShippingAmount;

        const touchAndGoLink = window.encodeURI(
          "https://payment.tngdigital.com.my/sc/bDLnuLJN9F"
        );
        const whatsAppLinkString = this.whatsAppLink.substring(
          0,
          this.whatsAppLink.indexOf("&text=") + 6
        );
        if (filteredProducts.length > 0) {
          if (this.extraShippingFee) {
            orderList.push(
              this.shippingLocation + "邮费(RM " + this.shippingAmount + ")"
            );
          }
          if (this.isFixedShippingAmount) {
            orderList.push(
              this.shippingLocation +
                "邮费(RM " +
                this.fixedShippingAmount +
                ")"
            );
          }
          this.whatsAppLink =
            whatsAppLinkString +
            "您好! 我需要购买%0a%0a" +
            orderList.join("%0a") +
            "%0a%0a总共 %2ARM " +
            this.overallTotal(products) +
            "%2A";

          if (this.extraShippingFee) {
            this.whatsAppLink +=
              "%0a消费满 RM" +
              this.minFreeShippingAmount +
              " 或以上即可免邮哦!";
          } else {
            this.whatsAppLink += " (" + this.shippingLocation + "免邮)";
          }
          this.whatsAppLink +=
            "%0a%0a_付款方式%3A_%0a%2ATouch n Go%2A%0a" +
            touchAndGoLink +
            "%0a%0a%2ABank Transfer%2A%0aPublic Bank%0a4538904736%0aLeng Hui Min";
        } else {
          this.whatsAppLink = whatsAppLinkString + "您好!";
        }
      },
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const query = params.get("q");
    let pinProducts;
    if (query === "dsj") {
      document.title = "秋香传统饼家 - 黑豆沙角";
      pinProducts = this.products.splice(2, 1);
      this.products.unshift(pinProducts[0]);
    } else if (query === "xyy") {
      document.title = "秋香传统饼家 - 小鸳鸯饼";
      pinProducts = this.products.splice(3, 1);
      this.products.unshift(pinProducts[0]);
    } else if (query === "sibut") {
      document.title = "秋香传统饼家 - 蜗牛饼";
      pinProducts = this.products.splice(4, 2);
      this.products.unshift(pinProducts[1]);
      this.products.unshift(pinProducts[0]);
    } else if (query === "package") {
      document.title = "秋香传统饼家 - 配套";
      pinProducts = this.products.splice(6, 3);
      this.products.unshift(pinProducts[2]);
      this.products.unshift(pinProducts[1]);
      this.products.unshift(pinProducts[0]);
    } else if (query === "mix") {
      document.title = "秋香传统饼家 - 混搭糕饼";
      pinProducts = this.products.splice(9, 8);
      this.products.unshift(pinProducts[7]);
      this.products.unshift(pinProducts[6]);
      this.products.unshift(pinProducts[5]);
      this.products.unshift(pinProducts[4]);
      this.products.unshift(pinProducts[3]);
      this.products.unshift(pinProducts[2]);
      this.products.unshift(pinProducts[1]);
      this.products.unshift(pinProducts[0]);
    } else if (query === "xxsb") {
      document.title = "秋香传统饼家 - 咸香脆饼";
      pinProducts = this.products.splice(17, 1);
      this.products.unshift(pinProducts[0]);
    } else if (query === "coffee") {
      document.title = "秋香传统饼家 - 南洋黑咖啡";
      pinProducts = this.products.splice(19, 1);
      this.products.unshift(pinProducts[0]);
    }

    if (params.get("kukup") === "1") {
      this.minFreeShippingAmount = 60;
      this.shippingAmount = 15;
      this.shippingLocation = "Kukup";
    }

    if (params.get("notFreeShipping") === "1") {
      this.minFreeShippingAmount = 0;
      this.isFixedShippingAmount = true;
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 600px;
  margin: auto;
  background: white;
  background-image: linear-gradient(
      90deg,
      rgba(200, 0, 0, 0.5) 50%,
      transparent 0
    ),
    linear-gradient(rgba(200, 0, 0, 0.5) 50%, transparent 0);
  background-size: 30px 30px;
  font-family: "Noto Sans SC", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$color1: #dde1e4;
$color2: #694d47;
$color3: #e58c50;
$color4: #f5c984;
$color5: #f0ddaa;
$color6: #f4eed0;
$paddingLeft: 4px;

.host {
  background-color: $color1;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.75);
  color: $color2;
}

button,
.button {
  background-color: $color4;
  border: 1px solid $color4;
  color: black;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  padding: 4px 8px;
  text-decoration: none;
  text-align: center;

  &.primary {
    background-color: $color2;
    border: 1px solid $color2;
    color: $color1;
  }

  &.light {
    background-color: #eb5307;
    border: 1px solid #eb5307;
    color: white;
  }

  &.medium {
    font-size: 14px;
    padding: 4px 8px;
  }

  &.small {
    font-size: 13px;
    padding: 2px 8px;
  }

  &:active {
    position: relative;
    top: 1px;
  }
}

.controlArea {
  height: 42px;
  display: flex;
  align-items: center;
  padding: 6px 14px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $color6;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  .overallAmount {
    font-size: 22px;
    font-weight: 700;
  }
  .extraShippingFee {
    margin-left: 4px;
    font-size: 16px;
    font-weight: normal;
  }
  .buttonArea {
    a {
      margin-left: 6px;
    }
  }
  .logo {
    margin: auto;
    height: 34px;
  }
}
.products {
  padding: 16px $paddingLeft;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 24px 8px;
  @media (max-width: 359px) {
    padding: 16px 8px;
    grid-template-columns: repeat(1, 2fr);
  }
}
.product {
  &.active {
    .frameImage {
      img,
      .price {
        filter: blur(1px);
      }
      .cover {
        opacity: 1;
        .box {
          transform: translateY(0);
        }
      }
    }
  }
  .frameImage {
    border-radius: 4px;
    overflow: hidden;
    padding-bottom: calc(4 / 3 * 100%);
    position: relative;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    img {
      position: absolute;
      display: block;
      width: 100%;
    }
    .imageLabel {
      position: absolute;
      bottom: -9px;
      right: 0;
      width: 38px;
    }
    .price {
      position: absolute;
      width: 100%;
      bottom: 5%;
      color: white;
      text-align: center;
      font-size: 20px;
      text-shadow: 2px 2px 5px rgb(0 0 0), 2px 2px 5px rgb(0 0 0),
        2px 2px 5px rgb(0 0 0);
    }
    .cover {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.3s;
      &.center {
        text-align: center;
      }
      .box {
        background-color: $color1;
        color: $color2;
        width: 90%;
        font-size: 18px;
        padding: 4px 0;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        transform: translateY(300%);
        transition: 0.3s ease-in;
        .description {
          width: 100%;
          padding: 4px 6px;
          font-size: 13px;
          line-height: 1.2;
          white-space: pre-line;
        }
        .selected {
          border-top-width: 0;
          border-top-color: $color2;
          border-top-style: dashed;
          max-height: 0;
          transition: 0.3s ease-out;
          overflow: hidden;
          text-align: center;
          font-size: 15px;
          &.open {
            border-top-width: 1px;
            max-height: 300px;
            transition: 0.3s ease-in;
          }
          .total {
            margin-bottom: 6px;
          }
        }
        .orderButtonArea {
          text-align: center;
          border-top: 1px dashed $color2;
          button:last-child {
            margin-left: 6px;
          }
        }
      }
    }
  }
  .soldOut {
    padding: 4px;
    position: absolute;
    left: 5%;
    top: calc(50% - 5px);
    width: 90%;
    background-color: $color1;
    color: $color2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 4px;
  }
  .learnMoreButtonArea {
    margin-top: 8px;
    text-align: center;
    button:last-child {
      margin-left: 10px;
    }
  }
}
.note {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}
.cta {
  position: sticky;
  bottom: 0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.73);
  .button {
    width: 100%;
    border-radius: 0;
    padding: 8px 16px;
  }
}
</style>
